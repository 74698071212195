import * as React from "react"
import Layout from '../components/global/layout.js'
import notFoundStyles from '../style/404.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'

const NotFoundPage = () => {
  return (
    <Layout>
      <div className={notFoundStyles.notFoundContainer}>
        <div className={notFoundStyles.svgContainer}>
          <svg className={notFoundStyles.notFoundPlug} xmlns="http://www.w3.org/2000/svg" width="659.555" height="531.05" viewBox="0 0 659.555 531.05">
            <g id="Gruppe_72" data-name="Gruppe 72" transform="translate(-439.469 36.173)">
              <g id="Gruppe_66" data-name="Gruppe 66" transform="matrix(0.921, 0.391, -0.391, 0.921, 479.93, 229.209)">
                <path id="Pfad_50" data-name="Pfad 50" d="M171.069,155.753H168.31a.913.913,0,0,1-.914-.912V93.009a7.361,7.361,0,0,0-7.36-7.36h-5.855a7.361,7.361,0,0,1-7.362-7.36V56.73a7.36,7.36,0,0,0-7.36-7.36H81.621a7.361,7.361,0,0,0-7.36,7.36V78.4a7.472,7.472,0,0,1-8.381,7.282,9.207,9.207,0,0,0-10.029,9.169v59.986a.914.914,0,0,1-.913.912H52.179a4.4,4.4,0,0,0-4.4,4.4v9.609a4.4,4.4,0,0,0,4.4,4.4h118.89a4.4,4.4,0,0,0,4.4-4.4v-9.609A4.4,4.4,0,0,0,171.069,155.753Z" transform="translate(0 0)" fill="none" stroke="#f2f2f2" stroke-miterlimit="10" stroke-width="25" />
                <g id="Gruppe_65" data-name="Gruppe 65" transform="translate(78.592 174.164)">
                  <path id="Rechteck_67" data-name="Rechteck 67" d="M0,0H16.245a0,0,0,0,1,0,0V43.278a4.988,4.988,0,0,1-4.988,4.988H4.988A4.988,4.988,0,0,1,0,43.278V0A0,0,0,0,1,0,0Z" fill="#f2f2f2" stroke="#f2f2f2" stroke-miterlimit="10" stroke-width="6.71" />
                  <path id="Rechteck_68" data-name="Rechteck 68" d="M0,0H16.245a0,0,0,0,1,0,0V43.278a4.988,4.988,0,0,1-4.988,4.988H4.988A4.988,4.988,0,0,1,0,43.278V0A0,0,0,0,1,0,0Z" transform="translate(49.816)" fill="#f2f2f2" stroke="#f2f2f2" stroke-miterlimit="10" stroke-width="6.71" />
                </g>
              </g>
              <path id="Pfad_51" data-name="Pfad 51" d="M-20123.842-9382.13s25.848-105.907,70.355-151.47,124.09-55.52,169.984,6.906,2.395,307.664,149.643,277.338,134.857-428.083,130.781-487.705" transform="translate(20687.463 9701.74)" fill="none" stroke="#f2f2f2" stroke-width="25" />
            </g>
          </svg>
        </div>

        <div className={notFoundStyles.notFoundTextContainer}>
          <h1><FormattedMessage id = "404.pagenotfound"/></h1>
          <p><FormattedMessage id = "404.tagline"/></p>
        </div>

        <div className={notFoundStyles.notFoundImageContainer}>
          4
          <svg className={notFoundStyles.outletDesktop} xmlns="http://www.w3.org/2000/svg" width="213.888" height="732.201" viewBox="0 0 213.888 732.201">
            <g id="Gruppe_71" data-name="Gruppe 71" transform="translate(-376.725 -534.24)">
              <g id="Gruppe_64" data-name="Gruppe 64" transform="translate(77.762 395.439)">
                <g id="Gruppe_63" data-name="Gruppe 63" transform="translate(360.019 199.002)">
                  <rect id="Rechteck_65" data-name="Rechteck 65" width="19.332" height="48.142" rx="3.518" transform="translate(31.101)" fill="#f2f2f2" />
                  <rect id="Rechteck_66" data-name="Rechteck 66" width="19.332" height="48.142" rx="3.518" transform="translate(-5.898)" fill="#f2f2f2" />
                </g>
                <circle id="Ellipse_50" data-name="Ellipse 50" cx="71.773" cy="71.773" r="71.773" transform="translate(311.463 151.301)" fill="none" stroke="#f2f2f2" stroke-miterlimit="10" stroke-width="25" />
              </g>
              <path id="Pfad_49" data-name="Pfad 49" d="M-20242.369-8990.845s-77.158,129.359,37.973,215.847,138.789,190.4,42.066,392.443" transform="translate(20680.18 9681.74)" fill="none" stroke="#f2f2f2" stroke-linecap="round" stroke-width="25" />
            </g>
          </svg>

          <svg className={notFoundStyles.outletMobile} xmlns="http://www.w3.org/2000/svg" width="1222.975" height="455.459" viewBox="0 0 1222.975 455.459">
            <g id="Gruppe_71" data-name="Gruppe 71" transform="translate(677.705 -534.24)">
              <g id="Gruppe_64" data-name="Gruppe 64" transform="translate(77.762 395.439)">
                <g id="Gruppe_63" data-name="Gruppe 63" transform="translate(360.019 199.002)">
                  <rect id="Rechteck_65" data-name="Rechteck 65" width="19.332" height="48.142" rx="3.518" transform="translate(31.101)" fill="#f2f2f2" />
                  <rect id="Rechteck_66" data-name="Rechteck 66" width="19.332" height="48.142" rx="3.518" transform="translate(-5.898)" fill="#f2f2f2" />
                </g>
                <circle id="Ellipse_50" data-name="Ellipse 50" cx="71.773" cy="71.773" r="71.773" transform="translate(311.463 151.301)" fill="none" stroke="#f2f2f2" stroke-miterlimit="10" stroke-width="25" />
              </g>
              <path id="Pfad_49" data-name="Pfad 49" d="M-20240.053-8986.335c-64.307,123.095,109.949,279.636-140.811,281.758s-959.654-92.714-959.752-92.562" transform="translate(20680.18 9681.74)" fill="none" stroke="#f2f2f2" stroke-linecap="round" stroke-width="25" />
            </g>
          </svg>



          4
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
